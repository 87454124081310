import { useEffect, useRef } from 'react';
import { useMapContext } from '../contexts/MapContext';

export function MapWrapper() {
  const mapRef = useRef<HTMLDivElement>(null);
  const { initMap } = useMapContext();

  useEffect(() => {
    initMap(mapRef.current!);
  }, [initMap]);

  return <div ref={mapRef} className="w-full h-screen" />;
}
