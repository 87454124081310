import { MapWrapper } from './components/MapWrapper';
import { Searchbar } from './components/Searchbar';
import { MapContextProvider } from './contexts/MapContext';

export function App() {
  return (
    <MapContextProvider>
      <div>
        <Searchbar />
        <MapWrapper />
      </div>
    </MapContextProvider>
  );
}
