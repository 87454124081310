import { Loader } from '@googlemaps/js-api-loader';
import { Cluster, MarkerClusterer, SuperClusterAlgorithm } from '@googlemaps/markerclusterer';
import { createContext, ReactNode, useCallback, useContext, useState } from 'react';
import { getPointDetails, getPoints, PointType } from '../utils/api';
import { customPin } from '../utils/pins';

const loader = new Loader({
  apiKey: 'AIzaSyDhv1A15MBbFuKnRPEXJNJ8PVcLZOkKITc',
  version: 'weekly',
});

interface MapContextInterface {
  map: google.maps.Map | null;
  initMap: (elem: HTMLDivElement) => void;
  moveToCoords: (coords: google.maps.LatLngLiteral, zoom: number) => void;
  moveToPlace: (query: string) => void;
}

export const MapContext = createContext<MapContextInterface>({} as MapContextInterface);
export const useMapContext = () => useContext(MapContext);

type MapProviderProps = { children: ReactNode };

export function MapContextProvider({ children }: MapProviderProps) {
  const [map, setMap] = useState<google.maps.Map | null>(null);

  const initMap = useCallback(async (elem: HTMLDivElement) => {
    await Promise.all([loader.importLibrary('core'), loader.importLibrary('maps'), loader.importLibrary('marker')]);

    const map = new google.maps.Map(elem, {
      mapId: 'fed53b1e38385b50',
      streetViewControl: false,
      mapTypeControl: false,
      center: { lat: 52.03626125, lng: 18.8924914 },
      zoom: 7,
    });

    try {
      const points = await getPoints();
      const markers = points?.map(point => markerRenderer(point, map));
      const bounds = new google.maps.LatLngBounds();
      markers.forEach(m => bounds.extend(m.position!));
      map.fitBounds(bounds);

      new MarkerClusterer({
        markers,
        map,
        algorithm: new SuperClusterAlgorithm({ radius: 128, maxZoom: 14 }),
        renderer: { render: clusterRenderer },
      });
    } catch (error) {
      console.error(error);
    }

    setMap(map);
  }, []);

  const moveToCoords = useCallback(
    (coords: google.maps.LatLngLiteral, zoom: number) => {
      if (!map) {
        return null;
      }

      map.setCenter(coords);
      map.setZoom(zoom);
    },
    [map],
  );

  const moveToPlace = useCallback(
    async (query: string) => {
      if (!map) {
        return null;
      }

      if (!query.trim()) {
        return null;
      }

      const geocoder = new google.maps.Geocoder();
      const { results } = await geocoder.geocode({ address: query.toLocaleLowerCase().trim() });

      if (results.length) {
        map.setCenter(results[0].geometry.location);
        map.setZoom(14);
      }
    },
    [map],
  );

  return (
    <MapContext.Provider
      value={{
        map,
        initMap,
        moveToCoords,
        moveToPlace,
      }}
    >
      {children}
    </MapContext.Provider>
  );
}

function clusterRenderer(cluster: Cluster) {
  const icon = document.createElement('div');
  icon.className = 'cluster';
  icon.textContent = `${cluster.count}`;

  return new google.maps.marker.AdvancedMarkerElement({
    position: cluster.position,
    content: icon,
  });
}

function markerRenderer(point: PointType, map: google.maps.Map) {
  const glyphImg = document.createElement('img');
  glyphImg.src = customPin[point.type] ? customPin[point.type] : customPin['zen'];
  glyphImg.width = 36;

  const marker = new google.maps.marker.AdvancedMarkerElement({
    position: new google.maps.LatLng({ lat: point.lat, lng: point.lng }),
    content: glyphImg,
  });

  const infoWindow = new google.maps.InfoWindow();

  marker.addListener('click', async () => {
    try {
      const details = await getPointDetails(point.id);

      infoWindow.setContent(`
      <div>
        <p class='text-primaryDark font-medium mb-2'>${details.name}</p>
        <p class='text-gray-600 text-xs'>${details.address}</p>
        <p class='text-gray-600 text-xs'>${details.city}, ${details.postCode}</p>
      </div>
    `);

      infoWindow.open(map, marker);
    } catch (error) {
      console.error(error);
    }
  });

  return marker;
}
