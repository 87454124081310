import axios from 'axios';

const instance = axios.create({
  baseURL: 'https://staging-app.zen.payticon.com',
});

export type PointType = {
  id: string;
  lat: number;
  lng: number;
  type: string;
};

export type PointDetailsType = {
  id: string;
  name: string;
  address: string;
  city: string;
  postCode: string;
  lat: number;
  lng: number;
  type: string;
};

export async function getPoints() {
  return instance.get<PointType[]>('/points').then(r => r.data);
}

export async function getPointDetails(id: string) {
  return instance.get<PointDetailsType>(`/points/${id}`).then(r => r.data);
}
