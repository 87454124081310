import carrefourSvg from '../assets/carrefour.svg';
import delikatesySvg from '../assets/delikatesy.svg';
import dinoSvg from '../assets/dino.svg';
import duzyBenSvg from '../assets/duzy_ben.svg';
import lewiatanSvg from '../assets/lewiatan.svg';
import lottoSvg from '../assets/lotto.svg';
import ruchSvg from '../assets/ruch.svg';
import spolemSvg from '../assets/spolem.svg';
import stokrotkaSvg from '../assets/stokrotka.svg';
import zabkaSvg from '../assets/zabka.svg';
import zenSvg from '../assets/zen.svg';

export const customPin: Record<string, string> = {
  carrefour: carrefourSvg,
  duzy_ben: duzyBenSvg,
  lewiatan: lewiatanSvg,
  ruch: ruchSvg,
  spolem: spolemSvg,
  stokrotka: stokrotkaSvg,
  delikatesy_centrum: delikatesySvg,
  zabka: zabkaSvg,
  dino: dinoSvg,
  zen: zenSvg,
  lotto: lottoSvg,
};
