export function getCurrentCoords() {
  return new Promise<google.maps.LatLngLiteral>((resolve, reject) => {
    navigator.geolocation.getCurrentPosition(
      position => {
        resolve({ lat: position.coords.latitude, lng: position.coords.longitude });
      },
      error => {
        reject(error);
      },
    );
  });
}
