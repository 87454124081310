import { FormEvent, useState } from 'react';
import { IoLocate, IoSearch } from 'react-icons/io5';
import { useMapContext } from '../contexts/MapContext';
import { getCurrentCoords } from '../utils/geolocation';
import { Spinner } from './Sipnner';

export function Searchbar() {
  const [query, setQuery] = useState('');
  const { map, moveToCoords, moveToPlace } = useMapContext();
  const [isLoading, setIsLoading] = useState(false);

  const handleCurrentPosition = async () => {
    try {
      setIsLoading(true);
      const coords = await getCurrentCoords();
      setQuery('');
      moveToCoords(coords, 14);
    } catch (error) {
      //
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    moveToPlace(query);
  };

  if (!map) {
    return null;
  }

  return (
    <div className="absolute top-6 w-full z-10 px-6 pointer-events-none">
      <form
        onSubmit={handleSearch}
        className="w-full lg:w-1/2 xl:w-1/3 2xl:w-1/4 p-4 rounded-md bg-gray-100 border border-gray-300 pointer-events-auto flex items-center"
      >
        <div className="relative w-full">
          <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
            <IoSearch className="w-4 h-4 text-gray-500" />
          </div>
          <input
            type="text"
            value={query}
            onChange={e => setQuery(e.target.value)}
            className="bg-gray-50 border border-gray-300 text-primary text-sm rounded-md block w-full pl-10 p-2"
            placeholder="Wpisz miasto lub ulicę"
          />
        </div>
        <button
          type="button"
          onClick={handleCurrentPosition}
          className="h-[38px] flex items-center justify-center p-2.5 ml-2 text-sm font-medium text-white bg-primary rounded-md hover:bg-primaryDark focus:ring-2 focus:outline-none focus:ring-gray-500 transition-colors"
        >
          {isLoading ? <Spinner /> : <IoLocate className="w-4 h-4" />}
          <span className="sr-only">Pobierz aktualną lokalizację</span>
        </button>
        <button
          type="submit"
          className="h-[38px] flex items-center justify-center p-2.5 ml-2 text-sm font-medium text-white bg-primaryLight rounded-md hover:bg-primary focus:ring-2 focus:outline-none focus:ring-gray-500 transition-colors"
        >
          <IoSearch className="w-4 h-4 text" />
          <span className="sr-only">Wyszukaj</span>
        </button>
      </form>
    </div>
  );
}
